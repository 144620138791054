
* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.NavCircleButton {
  background-color: #cacaca;
  border-radius: 30px;
  border: 0;
  color: black;
  display: block;
  font-size: 12px;
  font-weight: bold;
  height: 60px;
  padding: 8px;
  text-align: center;
  text-decoration: none;
  width: 60px;
}

a.NavCircleButton {
  padding: 16px 8px 8px;
}

.NavCircleButton:hover {
  cursor: pointer;
}


.NoteListNav__list {
  list-style-type: none;
  margin: 130px 0 0;
  padding: 0;
}

.NoteListNav__button-wrapper {
  margin: 0 0 0 auto;
  max-width: 180px;
}

.NoteListNav__add-folder-button {
  margin: 20px auto;
}
.NoteListNav__add-folder-button:hover {
  background-color: #29862e;
  color: white;
}

.NoteListNav__folder-link {
  background-color: #030f1f;
  color: #b5b3b3;
  display: block;
  font-family: var(--custom-font);
  font-size: 20px;
  margin: 0 0 8px auto;
  max-width: 180px;
  padding: 8px 16px;
  text-decoration: none;
}
.NoteListNav__folder-link:hover {
  color: #FFB72F;
  cursor: pointer;
}
.NoteListNav__folder-link.active {
  background-color: #102541;
}

.NoteListNav__num-notes {
  color: #adadad;
  display: block;
  float: right;
  font-size: 10px;
}


.NotePageNav__back-button {
  margin: 45px 20px 25px auto
}
.NotePageNav__back-button:hover {
  background-color: #FFB72F;
}

.NotePageNav__folder-name {
  color: #b5b3b3;
  font-family: var(--custom-font);
  font-size: 40px;
  height: 50vh;
  margin: 0 auto 0 auto;
  -webkit-text-orientation: upright;
          text-orientation: upright;
  width: 75px;
  -webkit-writing-mode: vertical-lr;
          writing-mode: vertical-lr;
}


.Note {
  --note-height: 88px;
  background-color: #010d1e;
  color: #cacaca;
  height: var(--note-height);
  margin-bottom: 8px;
  max-width: 380px;
  padding: 8px 0 8px 8px;
  position: relative;
}

.Note__delete {
  --note-button-height: 32px;
  background-color: #041a3a;
  border: 0;
  color: #bdbcbc;
  float: right;
  font-size: 12px;
  height: var(--note-button-height);
  position: relative;
  top: calc((var(--note-button-height) + 32px) * -1);
  -webkit-transform-origin: right bottom;
          transform-origin: right bottom;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  width: calc(var(--note-height));
}
.Note__delete:hover {
  background-color: #bd0909;
  color: white;
  cursor: pointer;
  font-weight: bold;
}

.Note__title {
  font-family: var(--custom-font);
  font-size: 20px;
  margin: 0;
  max-width: 80%;
  min-height: 24px;
}
.Note__title a {
  color: #cacaca;
  display: block;
  text-decoration: none;
}
.Note__title a:hover {
  color: #FFB72F;
}

.Note__dates {
  bottom: 8px;
  color: #a7a5a5;
  font-size: 14px;
  position: absolute;
}


.NoteListMain ul {
  list-style-type: none;
  margin: 0;
  padding: 10px;
}

.NoteListMain__button-container {
  max-width: 380px;
  margin: 0 10px;
}

.NoteListMain__add-note-button {
  margin: 0 auto 20px;
}
.NoteListMain__add-note-button:hover {
  background-color: #29862e;
  color: white;
}


.NotePageMain {
  background-color: #efefef;
  height: 100%;
  padding: 10px;
  width: 100%;
}

.NotePageMain__content {
  color: #3a3939;
  max-width: 380px;
}

.addNoteOrFolder {
  color: white;
  text-align: center;
}

.validationElement {
  color: red;
}

.App {
  --custom-font: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  display: grid;
  grid-template-areas: "sidebar header" "sidebar main";
  grid-template-columns: 1fr 2.3fr;
  grid-template-rows: 120px 1fr;
  min-height: 100vh;
}

.App__nav {
  background-color: #0b1b32;
  color: #fff;
  grid-area: sidebar;
  height: 100vh;
  overflow: auto;
}

.App__header {
  background-color: #0b1f3a;
  color: #bdbdbd;
  font-family: var(--custom-font);
  grid-area: header;
  padding-left: 16px;
  padding-top: 32px;
  text-shadow: 2px 1px 2px black;
}

.App__header a {
  color: #bdbdbd;
}

.App__main {
  background-color: #102541;
  grid-area: main;
  height: calc(100vh - 120px);
  overflow: auto;
}

