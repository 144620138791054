
.NotePageMain {
  background-color: #efefef;
  height: 100%;
  padding: 10px;
  width: 100%;
}

.NotePageMain__content {
  color: #3a3939;
  max-width: 380px;
}

.addNoteOrFolder {
  color: white;
  text-align: center;
}

.validationElement {
  color: red;
}